@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
/* @import "~animate.css/animate.min.css"; */

[v-cloak] { display:none; }
[wire\:loading], [wire\:loading\.delay] {
  display: none;
}
[x-cloak] { display: none; }

[wire\:offline] {
  display: none;
}

[wire\:dirty]:not(textarea):not(input):not(select) {
  display: none;
}

html {
  scroll-behavior: smooth !important;
}


/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}
